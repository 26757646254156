import q1img from './Assets/Images/q1.png';
import q2img from './Assets/Images/q2.png';
import q4img from './Assets/Images/q4.png';
import q6img from './Assets/Images/q6.png';
import q7img from './Assets/Images/q7.png';
import q9img from './Assets/Images/q9.png';
import q10img from './Assets/Images/q10.png';
import q11img from './Assets/Images/q11.png';
import q12img from './Assets/Images/q12.png';
import q15img from './Assets/Images/q15.png';
import q16img from './Assets/Images/q16.png';
import q18img from './Assets/Images/q18.png';
import q20img from './Assets/Images/q20.png';
import q21img from './Assets/Images/q21.png';
import q24img from './Assets/Images/q24.png';

export const questions_statistics = [
  {
    id: 1,
    title: <>According to similar surveyed institutions, <b>20%</b> of ministries also <b>use Phos Creative</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 2,
    title: <>According to similar surveyed institutions, <b>60%</b> of ministries also <b>use Podbean</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "45px", width: "auto" }
  },
  {
    id: 3,
    title: <>According to similar surveyed institutions, <b>40%</b> of ministries also <b>use MS Office Outlook</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 4,
    title: <>According to similar surveyed institutions, <b>20%</b> of ministries also <b>use Polaris Direct</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "115px", width: "auto" }
  },
  {
    id: 5,
    title: <>According to similar surveyed institutions, <b>40%</b> of ministries also <b>attend the Missio Nexus Annual Conference</b>.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 6,
    title: <>According to similar surveyed institutions, <b>40%</b> of ministries also <b>are affiliated with the Accord Network</b>.</>,
    desc: "",
    image: null,
    dimensions: { height: "60px", width: "auto" }
  },
  {
    id: 7,
    title: <>According to similar surveyed institutions, <b>70%</b> of ministries also <b>use the Catastrophic Health Insurance plan</b>.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 8,
    title: <>According to similar surveyed institutions, <b>50%</b> of ministries also <b>use Blue Cross Blue Shield (BCBS)</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 9,
    title: <>According to similar surveyed institutions, <b>50%</b> of ministries also <b>use Manulife Financial</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 10,
    title: <>According to similar surveyed institutions, <b>40%</b> of ministries also <b>use Missionwell</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 11,
    title: <>According to similar surveyed institutions, <b>50%</b> of ministries also <b>use CapinCrouse LLP</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 12,
    title: <>According to similar surveyed institutions, <b>20%</b> of ministries also <b>use Breeze</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 13,
    title: <>According to similar surveyed institutions, <b>30%</b> of ministries also <b>use Certify</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 14,
    title: <>According to similar surveyed institutions, <b>60%</b> of ministries also <b>use Brotherhood Mutual</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 15,
    title: <>According to similar surveyed institutions, <b>60%</b> of ministries also <b>use Cornerstone Management</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 16,
    title: <>According to similar surveyed institutions, <b>70%</b> of ministries also <b>use The Gage Group</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 17,
    title: <>According to similar surveyed institutions, <b>10%</b> of ministries also <b>use Blackbaud for their donations</b>.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 18,
    title: <>According to similar surveyed institutions, <b>70%</b> of ministries also <b>use Slack</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 19,
    title: <>According to similar surveyed institutions, <b>20%</b> of ministries also <b>use Squarespace</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 20,
    title: <>According to similar surveyed institutions, <b>60%</b> of ministries also <b>use ACS Technologies</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 21,
    title: <>According to similar surveyed institutions, <b>70%</b> of ministries also <b>use Brotherhood Mutual Insurance Company</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 22,
    title: <>According to similar surveyed institutions, <b>70%</b> of ministries also <b>use Chubb</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 23,
    title: <>According to similar surveyed institutions, <b>70%</b> of ministries also <b>use Brotherhood Mutual Insurance Company</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 24,
    title: <>According to similar surveyed institutions, <b>40%</b> of ministries also <b>use Brotherhood Mutual Insurance Company</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 25,
    title: <>According to similar surveyed institutions, <b>20%</b> of ministries also <b>use Brotherhood Mutual Insurance Company</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 26,
    title: <>According to similar surveyed institutions, <b>60%</b> of ministries also <b>use Faith Ventures</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 27,
    title: <>According to similar surveyed institutions, <b>60%</b> of ministries also <b>use Brotherhood Mutual Insurance Company</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 28,
    title: <>According to similar surveyed institutions, <b>20%</b> of ministries also <b>use Breeze ChMS</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 29,
    title: <>According to similar surveyed institutions, <b>30%</b> of ministries also <b>use MissionMinder</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 30,
    title: <>According to similar surveyed institutions, <b>30%</b> of ministries also <b>use Launch Globalr</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 31,
    title: <>According to similar surveyed institutions, <b>20%</b> of ministries also <b>attend Cross Conference frequently</b></>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 32,
    title: <>According to similar surveyed institutions, <b>30%</b> of ministries also <b>use Valeo</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 33,
    title: <>According to similar surveyed institutions, <b>40%</b> of ministries also <b>use MotivationsAI</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  },
  {
    id: 34,
    title: <>According to similar surveyed institutions, <b>20%</b> of ministries also <b>use Tourismora</b> for their needs.</>,
    desc: "",
    image: null,
    dimensions: { height: "80px", width: "auto" }
  }
];
