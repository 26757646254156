import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import schoolImg from '../../Assets/Images/logo-mob.png';
import profitImg from '../../Assets/Images/logo-mob.png';
import nonProfitImg from '../../Assets/Images/logo-mob.png';
import instituteImg from '../../Assets/Images/logo-mob.png';
import rightTickImg from '../../Assets/Images/right-tick.png';
import churchImage from "../../Assets/Images/churchImage.png"
import ministryImage from "../../Assets/Images/ministryImage.png"
import selectedMinistryImage from "../../Assets/Images/selectedMinistryImage.png"
import selectedChurchImage from "../../Assets/Images/selectedChurchImage.png"
import AssessmentTypeContext from "../../Store/AssessmentTypeContext";


const categories = [
  // { id: 1, img: schoolImg, text: [""], name: "School" },
  // { id: 2, img: instituteImg, text: [""], name: "Educational Institution" },
  // { id: 3, img: nonProfitImg, text: [""], name: "Non-Profit Organization" },
  // { id: 4, img: profitImg, text: [""], name: "For-Profit Business" },
  { id: 1, img: churchImage, selectedImg: selectedChurchImage, text: [""], name: "Church" },
  { id: 2, img: ministryImage, selectedImg: selectedMinistryImage, text: [""], name: "Ministry" },
];


const PreAssessment = () => {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [categoryIsHover, setCategoryIsHover] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const assessmentTypeCtx = useContext(AssessmentTypeContext)

  function goToAssessmentPage() {
    navigate("/start?" + searchParams, { replace: true });
  }

  // useEffect(() => {
  //   const data = window.localStorage.getItem("SELECTED_CATEGORY");
  //   if (data !== null) setSelectedCategory(JSON.parse(data));
  // }, []);
  useEffect(() => {
    const data = localStorage.getItem("SELECTED_CATEGORY");
    if (data !== null) setSelectedCategory(JSON.parse(data));
    fetchAdditionalQs();
  }, []);

  // useEffect(() => {
  //   window.localStorage.setItem("SELECTED_CATEGORY", JSON.stringify(selectedCategory));
  // }, [selectedCategory]);


  const handleCategorySelect = (category) => {
    // console.log("category", category);
    // setSelectedCategory(category);
    setSelectedCategory((prevCategory) => {
      const newCategory = prevCategory?.id === category.id ? null : category;
      window.localStorage.setItem("SELECTED_CATEGORY", JSON.stringify(newCategory));
      return newCategory;
    });
    const updatedQuestions = questions?.map((question) => {
      if (question.id == 1676) {
        return { ...question, answerTxt: selectedCategory?.name };
      }
      return { ...question };
    });
    setQuestions(updatedQuestions);
    handleCreateAssessment(category?.name);
    // setTimeout(() => {
    //   // goToAssessmentPage();
    // }, 500);
  };

  function fetchAdditionalQs() {
    setIsLoading(true);
    const additionalQsPageId = 911;
    let clientId = process.env.REACT_APP_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        console.log("data", data);
        setQuestions(data?.questions);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }).catch((err) => {
      console.error("Failed to fetch additional questions...", err);
      setIsLoading(false);
    });
  }

  const createAssessment = async () => {
    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }
    const clientId = process.env.REACT_APP_CLIENT_ID;
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
        method: 'POST', mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ clientId, isMember, leaderId, userId })
      });
      const data = await response.json();
      if (data.status === "success") {
        console.log("data", data);
      }
      return data;
    } catch (err) {
      console.error("Failed to create new assessment...", err);
      throw err;
    }
  }

  const getAnswerValue = (question, categoryName) => {
    return question?.answers?.filter((item) => item?.desktop_text == categoryName)[0]?.value
  }

  const answerAdditionalQ = async (userId, categoryName) => {
    setIsLoading(true);
    const updatedQuestions = questions?.map((question) => {
      if (question.id == 1676) {
        return { ...question, answerTxt: categoryName || "", answer: getAnswerValue(question, categoryName) };
      }
      return { ...question };
    });
    const jsonBody = { questions: updatedQuestions, pageId: 911, };
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + userId, {
        method: 'PUT', mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jsonBody)
      });
      const data = await response.json();
      if (data.status === "success") {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.error("Failed to submit answers...", data);
      }
    } catch (err) {
      console.error("Failed to submit answers...", err);
      setIsLoading(false);
      throw err;
    }
  }

  const handleCreateAssessment = async (categoryName) => {
    try {
      const data = await createAssessment();
      if (data && data.status === "success") {
        await answerAdditionalQ(data.id, categoryName);
        navigate("/start/" + data.id + "?" + searchParams, { replace: true });
        assessmentTypeCtx.updateAssessmentType(categoryName, data.id)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };



  return (
    <div className="container pre-assessment">
      <h1>Select your organization type</h1>
      <div className="assessment-categories">
        {categories.map((category) => (
          <div key={category.id}
            className={`category ${(selectedCategory?.id === category.id) ? "selected" : selectedCategory?.name ? "non-selected" : ""} ${(categoryIsHover === category?.id) && "hover"}`}
            onClick={() => {
              handleCategorySelect(category)
            }}
            onMouseEnter={(e) => {
              setCategoryIsHover(category?.id)
            }}
            onMouseLeave={(e) => {
              setCategoryIsHover(0)
            }}
          >
            <div className={`right-tick-img-container`} style={selectedCategory?.id === category.id ? {} : { display: 'none' }}>
              <img src={rightTickImg} alt="" />
            </div>
            <div className="img-container">
              {/* <img src={category.img} alt="" /> */}
              <img src={(selectedCategory?.id === category.id || categoryIsHover === category.id) ? category.selectedImg : category.img} alt="" />
            </div>
            <p className="text">{category.name}</p>
          </div>
        ))}
      </div>
      <div className="button" style={selectedCategory ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <Button className="cta" onClick={handleCreateAssessment}>Continue</Button>
      </div>
    </div>
  );
}

export default PreAssessment;

