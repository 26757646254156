import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../.././Assets/Images/testimonial.png';
// import topQ from '../Assets/Images/topQ.svg';
import topQ from '../.././Assets/Images/leftQuote.png';
import bottomQ from '../.././Assets/Images/rightQuote.png';
import quotation from "../.././Assets/Images/quote-single.png";

const Testimonial = () => {

  const { id } = useParams();

  return (
    <div className="testimonial-container">
        <div className="testimonial">
        <div className="testi">
            <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
            <p>I have a tendency to play in my own sandbox with the people that I know in my organization when it comes to missions. <span style={{fontWeight:700}} ><i>Missio Nexus</i></span>  has helped me get out of that sandbox and meet people doing all kinds of different things. Whether it is other churches or different mission agencies, I get to see how they are participating in the Great Commission. That has been a blessing to me.
            
            <span className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{transform:'rotate(180deg)'}} alt="quote icon" /></div></span>
            </p>
            
            
          </div>
        <div>
          <div className="avatar">
            <img src={testimonialImage} alt="pastor" width={125} height={125} />
          </div>
        </div>
        <div style={{display:'flex', justifyContent:'center' , flexDirection:'column', alignItems:'center'}}>          
          <div className="designation">
          {/* Chris Martinez<p className="designation-inner">Lead Pastor, Lakeside Church</p>  */}
          <p className="designation-inner">
            <p className="name">Mark Byrom</p>
            <p className="p2">Church Missions Pastor,</p>
            <p className="p3">Appleton Alliance Church</p>
          </p> 
          </div>
        </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
  );
}

export default Testimonial;
