import { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { isEmail, isURL } from "validator";

import prevArrow from "../../Assets/Images/prevArrow.svg";
import nextArrow from "../../Assets/Images/next-arrow.svg";
import hourGlass from "../../Assets/Images/hourglass.gif";

import AdditionalQuestionContext from "../../Store/AdditionalQuestionContext";
import { color } from "highcharts";

const givingValues = [
  { value: "Continue To Give", label: "Continue To Give" },
  { value: "Donorbox", label: "Donorbox" },
  { value: "easyTithe", label: "easyTithe" },
  { value: "Givelify", label: "Givelify" },
  { value: "Kindrid", label: "Kindrid" },
  { value: "MoGiv", label: "MoGiv" },
  { value: "OnlineGiving", label: "OnlineGiving" },
  { value: "PayPal", label: "PayPal" },
  { value: "Planning Center", label: "Planning Center" },
  { value: "Pushpay", label: "Pushpay" },
  { value: "RebelGive", label: "RebelGive" },
  { value: "SecureGive", label: "SecureGive" },
  { value: "Tithe.ly", label: "Tithe.ly" },
  { value: "Other", label: "Other" },
  { value: "None", label: "None" },
];

const roleValues = [
  { value: "Church Staff - Missions Leader/Pastor", label: "Church Staff - Missions Leader/Pastor" },
  { value: "Church Staff - Missions Team Member", label: "Church Staff - Missions Team Member" },
  { value: "Church Staff - Senior Pastor", label: "Church Staff - Senior Pastor" },
  { value: "Field Staff - Missionary Candidate", label: "Field Staff - Missionary Candidate" },
  { value: "Field Staff - Other", label: "Field Staff - Other" },
  { value: "Field Staff - Regional, Country, or Area leader", label: "Field Staff - Regional, Country, or Area leader" },
  { value: "Field Staff - Team Leader", label: "Field Staff - Team Leader" },
  { value: "Field Staff - Team Member", label: "Field Staff - Team Member" },
  { value: "Ministry Staff - Short-Term Ministries Team Leader/Director/VP", label: "Ministry Staff - Short-Term Ministries Team Leader/Director/VP" },
  { value: "Ministry Staff - Administrative Assistant", label: "Ministry Staff - Administrative Assistant" },
  { value: "Ministry Staff - Administrator", label: "Ministry Staff - Administrator" },
  { value: "Ministry Staff - Advancement/Development/Fundraising Team Leader/Director/VP/CDO", label: "Ministry Staff - Advancement/Development/Fundraising Team Leader/Director/VP/CDO" },
  { value: "Ministry Staff - Advancement/Development/Fundraising Team Member", label: "Ministry Staff - Advancement/Development/Fundraising Team Member" },
  { value: "Ministry Staff - CEO/President Denominational Missions Leader", label: "Ministry Staff - CEO/President Denominational Missions Leader" },
  { value: "Ministry Staff - Chief Financial Officer", label: "Ministry Staff - Chief Financial Officer" },
  { value: "Ministry Staff - Chief Operating Officer", label: "Ministry Staff - Chief Operating Officer" },
  { value: "Ministry Staff - Church Relations Staff", label: "Ministry Staff - Church Relations Staff" },
  { value: "Ministry Staff - Church Relations Team Leader/Director/VP", label: "Ministry Staff - Church Relations Team Leader/Director/VP" },
  { value: "Ministry Staff - Communications Team Leader/Director/VP", label: "Ministry Staff - Communications Team Leader/Director/VP" },
  { value: "Ministry Staff - Communications Team Member", label: "Ministry Staff - Communications Team Member" },
  { value: "Ministry Staff - Executive Assistant", label: "Ministry Staff - Executive Assistant" },
  { value: "Ministry Staff - Finance/Accounting Team Leader/Director/VP", label: "Ministry Staff - Finance/Accounting Team Leader/Director/VP" },
  { value: "Ministry Staff - Finance/Accounting Team Member", label: "Ministry Staff - Finance/Accounting Team Member" },
  { value: "Ministry Staff - IT Team Leader/Director/VP", label: "Ministry Staff - IT Team Leader/Director/VP" },
  { value: "Ministry Staff - IT Team Member", label: "Ministry Staff - IT Team Member" },
  { value: "Ministry Staff - Member Care Team Leader/Director/VP", label: "Ministry Staff - Member Care Team Leader/Director/VP" },
  { value: "Ministry Staff - Member Care Team Member", label: "Ministry Staff - Member Care Team Member" },
  { value: "Ministry Staff - Mobilization Team Leader/Director/VP", label: "Ministry Staff - Mobilization Team Leader/Director/VP" },
  { value: "Ministry Staff - Mobilization Team Member", label: "Ministry Staff - Mobilization Team Member" },
  { value: "Ministry Staff - Personnel or HR Team Leader/Director/VP", label: "Ministry Staff - Personnel or HR Team Leader/Director/VP" },
  { value: "Ministry Staff - Personnel or HR Team Member", label: "Ministry Staff - Personnel or HR Team Member" },
  { value: "Ministry Staff - Second in Command (EVP/COO/SVP)", label: "Ministry Staff - Second in Command (EVP/COO/SVP)" },
  { value: "Ministry Staff - Short-Term Ministries Staff", label: "Ministry Staff - Short-Term Ministries Staff" },
  { value: "Ministry Staff - Training Team Leader/Director/VP", label: "Ministry Staff - Training Team Leader/Director/VP" },
  { value: "Ministry Staff - Training Team Member", label: "Ministry Staff - Training Team Member" },
  { value: "Mission Educator", label: "Mission Educator" },
  { value: "Service Provider/Vendor", label: "Service Provider/Vendor" },
  { value: "Student", label: "Student" }
];

const churchNameValues = [
  { value: "Church 1", label: "Church 1" },
  { value: "Church 2", label: "Church 2" },
  { value: "Church 3", label: "Church 3" },
  { value: "Church 4", label: "Church 4" },
  { value: "Church 5", label: "Church 5" },
];

// const roleValues = [
//   { value: "Role 1", label: "Role 1" },
//   { value: "Role 2", label: "Role 2" },
//   { value: "Role 3", label: "Role 3" },
//   { value: "Role 4", label: "Role 4" },
//   { value: "Role 5", label: "Role 5" },
// ];

const denominationValues = [
  {
    value: "African Methodist Episcopal Zion",
    label: "African Methodist Episcopal Zion",
  },
  {
    value: "African Methodist Episcopal",
    label: "African Methodist Episcopal",
  },
  { value: "Assembly of God", label: "Assembly of God" },
  { value: "Baptist", label: "Baptist" },
  { value: "Baptist - Missionary", label: "Baptist - Missionary" },
  { value: "Baptist - National", label: "Baptist - National" },
  { value: "Baptist - SBC", label: "Baptist - SBC" },
  { value: "Catholic", label: "Catholic" },
  {
    value: "Christian Methodist Episcopal",
    label: "Christian Methodist Episcopal",
  },
  { value: "Church of Christ", label: "Church of Christ" },
  { value: "Church of God", label: "Church of God" },
  { value: "Church of God in Christ", label: "Church of God in Christ" },
  { value: "Episcopal", label: "Episcopal" },
  { value: "Evangelical", label: "Evangelical" },
  { value: "Jewish", label: "Jewish" },
  { value: "Lutheran", label: "Lutheran" },
  { value: "Lutheran - ELCA", label: "Lutheran - ELCA" },
  { value: "Lutheran - Missouri Synod", label: "Lutheran - Missouri Synod" },
  { value: "Methodist", label: "Methodist" },
  { value: "Non - denominational", label: "Non - denominational" },
  { value: "Other Denomination", label: "Other Denomination" },
  { value: "Pentecostal", label: "Pentecostal" },
  { value: "Presbyterian", label: "Presbyterian" },
];

const Details = (props) => {
  const addCtx = useContext(AdditionalQuestionContext);
  let navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMember, setIsMember] = useState(true);

  const [userId, setUserId] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [selectedJobTitle, setSelectedJobTitle] = useState();
  const [selectedDenomination, setSelectedDenomination] = useState();
  const [givingProvider, setGivingProvider] = useState("");
  const [selectedProvider, setSelectedProvider] = useState();
  const [jobTitleMember, setJobTitleMember] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [denomination, setDenomination] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [fullNameError, setFullNameError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [jobTitleError, setJobTitleError] = useState(false);
  const [denominationError, setDenominationError] = useState(false);
  const [givingProviderError, setGivingProviderError] = useState(false);
  const [schoolNameError, setSchoolNameError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [showMyResultsBtn, setShowMyResultsBtn] = useState(false);
  const [selectedOrgName, setSelectedOrgName] = useState();
  const [orgName, setOrgName] = useState("");
  const [selectedRole, setSelectedRole] = useState();
  const [role, setRole] = useState("");
  const [orgNameError, setOrgNameError] = useState(false);
  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState(false);
  const [avgWeeklyAttd, setAvgWeeklyAttd] = useState("");
  const [avgWeeklyAttdError, setAvgWeeklyAttdError] = useState("");
  const [churchSize, setChurchSize] = useState("");
  const [churchSizeError, setChurchSizeError] = useState(false);

  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    getUserDetails();
    // if(searchParams.get("member") != null) {
    //   getUserDetails();
    // } else {
    //   setIsMember(false);
    // }
    // document.body.style.backgroundColor = "#EEEEEE";
  }, []);

  // fullNameRegex allows letters, diacritics, spaces, single quotes, hyphens. needs one space so requires at least 2 words
  const fullNameRegex = /^([A-Za-zÀ-ÖØ-öø-ÿ'-]+ +)+[A-Za-zÀ-ÖØ-öø-ÿ'-]+$/;
  // multiWordRegex allows letters, numbers, diacritics, spaces, single quotes, hyphens, and '&' (allows multiple words)
  const multiWordRegex = /^(?=.*\p{L})[\w\s'&\-]+$/u;
  // zipCodeRegex Uppercase and lowercase letters, Digits, Spaces, hyphens, and The length can be between 3 and 30 characters
  const zipCodeRegex = /^[a-zA-Z0-9\s-]{5,30}$/;

  // const validUrlRegex =
  //   /^((ftp|http[s]?):\/\/)?(www\.)?([a-z0-9]+)\.[a-z]{2,5}(\.[a-z]{8})?$/;

  const validUrlRegex =
    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

  useEffect(() => {
    const updateFormCtxDetails = (fieldName, value) => {
      if (value !== addCtx.formDetails?.[fieldName]) {
        addCtx.addFormDetails((prev) => ({ ...prev, [fieldName]: value }));
      }
    };

    updateFormCtxDetails("fullName", fullName);
    // updateFormCtxDetails("firstName", firstName);
    // updateFormCtxDetails("lastName", lastName);
    updateFormCtxDetails("email", email);
    // updateFormCtxDetails("orgName", orgName);
    updateFormCtxDetails("website", website);
    updateFormCtxDetails("role", role);
    // updateFormCtxDetails("zipCode", zipCode);
    // updateFormCtxDetails("avgWeeklyAttd", avgWeeklyAttd);
    // updateFormCtxDetails("denomination", denomination);
    // updateFormCtxDetails("jobTitle", jobTitle);

    // updateFormCtxDetails("givingProvider", givingProvider);
  }, [firstName, lastName, fullName, email, website, orgName, role, zipCode, avgWeeklyAttd, jobTitle, denomination,]);

  useEffect(() => {
    if (isMember) {
      if (
        // firstName?.length &&
        // lastName?.length &&
        fullName?.length &&
        email?.length &&
        role?.length
      ) {
        setBtnDisabled(false);
      } else setBtnDisabled(true);
    } else if (!isMember) {
      if (
        // firstName?.length &&
        // lastName?.length &&
        fullName?.length &&
        email?.length &&
        website?.length &&
        role?.length
        //jobTitle?.length  &&
        // zipCode?.length
      ) {
        setBtnDisabled(false);
      } else setBtnDisabled(true);
    }

    checkFormValid();
  }, [firstName, lastName, fullName, email, website, orgName, role, denomination, isMember, zipCode, avgWeeklyAttd, jobTitle,]);

  useEffect(() => {
    let formDetails = addCtx.formDetails;

    // if (formDetails?.firstName?.length) setFirstName(formDetails.firstName);
    // if (formDetails?.lastName?.length) setLastName(formDetails.lastName);
    if (formDetails?.fullName?.length) setFullName(formDetails.fullName);
    if (formDetails?.email?.length) setEmail(formDetails.email);
    // if (formDetails?.website?.length) setWebsite(formDetails.website);
    if (formDetails?.orgName?.length) setOrgName(formDetails.orgName);
    if (formDetails?.role?.length) setRole(formDetails.role);
    // if (formDetails?.zipCode?.length) setZipCode(formDetails.zipCode);
    // if (formDetails?.avgWeeklyAttd?.length) setAvgWeeklyAttd(formDetails.avgWeeklyAttd);
    // if (formDetails?.jobTitle?.length) setJobTitle(formDetails.jobTitle);
    // if (formDetails?.denomination?.length) setDenomination(formDetails.denomination);
    if (formDetails?.denomination?.length) {
      let selected = denominationValues?.find(
        (val) => val.value === formDetails?.denomination
      );
      // console.log("typeof selected", typeof selected, selected);
      setSelectedDenomination(selected);
      selected?.value && setDenomination(selected?.value);
    }

    // if (formDetails?.orgName?.length) {
    //   let selected = churchNameValues?.find(church => church.value === formDetails?.orgName);
    //   // console.log("typeof selected", typeof selected, selected);
    //   setSelectedOrgName(selected);
    //   selected?.value && setOrgName(selected?.value);
    // }
    // if (formDetails?.givingProvider?.length) {
    //   let selected = givingValues?.find(val => val.value === formDetails?.givingProvider);
    //   // console.log("typeof selected", typeof selected, selected);
    //   setSelectedProvider(selected);
    //   selected?.value && setGivingProvider(selected?.value);
    // }
  }, [addCtx.formDetails]);

  console.log("addCtx.formDetails", addCtx.formDetails);
  // console.log("typeof jobTitle", typeof jobTitle, jobTitle);

  const getUserDetails = () => {
    setIsLoading(true);

    // fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
    fetch(
      process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/details",
      {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          if (data.isMember == true) {
            setIsMember(true);
            setUserId(data.userId);
            // setFirstName(data.firstName);
            // setLastName(data.lastName);
            // data.fullName?.trim() &&
            setFullName(data.fullName?.trim());
            setEmail(data.email);
            // setJobTitle("none");
            // setJobTitle("");
          } else {
            setIsMember(false);
          }

          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  };

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  };

  const updateFullName = (event) => {
    setFullName(event.target.value);
    checkFormValid();
  };

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  };

  // const updateJobTitle = (event) => {
  const updateJobTitle = (selectedOption) => {
    // console.log(event.target.value);
    // console.log(selectedOption.value);
    // setSelectedJobTitle(selectedOption);
    setJobTitle(selectedOption.value);
    // setJobTitle(event.target.value);
    checkFormValid();
  };

  const updateOrgName = (event) => {
    // if orgName is dropdown list
    // const updateOrgName = (selectedOption) => {
    // setSelectedOrgName(selectedOption);
    // setOrgName(selectedOption.value);
    setOrgName(event.target.value);
    checkFormValid();
  };

  const updateRole = (selectedOption) => {
    setSelectedRole(selectedOption);
    setRole(selectedOption.value);
    checkFormValid();
  };

  // const updateWebsite = (event) => {
  //   setWebsite(event.target.value);
  //   checkFormValid();
  // };

  const updateWebsite = (event) => {
    const websiteInput = event.target.value.trim();

    setWebsite(websiteInput);
    setWebsiteError(!validUrlRegex.test(websiteInput));
    checkFormValid();
  };

  const updateGivingProvider = (selectedOption) => {
    setSelectedProvider(selectedOption);
    setGivingProvider(selectedOption.value);
    checkFormValid();
  };

  // const updateSchoolName = (event) => {
  //   setSchoolName(event.target.value);
  //   checkFormValid();
  // }

  const updateDenomination = (selectedOption) => {
    // const updateDenomination = (event) => {
    // setDenomination(event.target.value);
    // console.log("DENOMINATION", event.value);
    setSelectedDenomination(selectedOption);
    setDenomination(selectedOption.value);
    checkFormValid();
  };

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  };
  const updateAvgWeeklyAttd = (event) => {
    setAvgWeeklyAttd(event.target.value);
    checkFormValid();
  };

  const updateChurchSize = (event) => {
    setChurchSize(event.target.value);
    checkFormValid();
  };

  const checkFormValid = () => {
    const jobRoleRegex = /^[A-Za-z\s\-\/]+$/;

    // console.log("----------------------------------------------------------------");
    // console.log("form validity - First Name: ", multiWordRegex.test(firstName?.trim()));
    // console.log("form validity - Last Name: ", multiWordRegex.test(lastName?.trim()));
    // console.log("form validity - Email: ", isEmail(email?.trim()));
    // console.log("form validity - Website: ", isURL(website?.trim()));
    // console.log("form validity - Zip Code Length (4-12): ", zipCode?.trim()?.length > 3 && zipCode?.trim()?.length < 13);
    // console.log("form validity - Website Length > 0: ", website?.trim()?.length > 0);
    // console.log("form validity - Zip Code is a Number: ", !isNaN(zipCode?.trim()));
    // console.log("form validity - Role: ", jobRoleRegex.test(role?.trim()));
    // console.log("----------------------------------------------------------------");

    if (isMember) {
      // if (fullNameRegex.test(fullName?.trim()) && isEmail(email?.trim())) {
      if (
        // multiWordRegex.test(firstName?.trim()) &&
        // multiWordRegex.test(lastName?.trim()) &&
        fullNameRegex.test(fullName?.trim()) &&
        isEmail(email?.trim()) &&
        jobRoleRegex.test(role?.trim())
      ) {
        console.log("We get here");
        setIsFormValid(true);
        setShowMyResultsBtn(true);
      } else {
        setIsFormValid(false);
        setShowMyResultsBtn(false);
      }
    } else {
      // if (fullNameRegex.test(fullName?.trim()) && isEmail(email?.trim()) && multiWordRegex.test(orgName?.trim()) && isURL(website?.trim()) && zipCode?.trim()?.length > 3 && zipCode?.trim()?.length < 13 && role?.trim()?.length > 0  && !isNaN(zipCode?.trim()) ) {
      // if (
      //   // multiWordRegex.test(firstName?.trim()) &&
      //   // multiWordRegex.test(lastName?.trim()) &&
      //   fullNameRegex.test(fullName?.trim()) &&
      //   isEmail(email?.trim()) &&
      //   validUrlRegex.test(website?.trim()) &&
      //   // zipCode?.trim()?.length >= 5 &&
      //   // zipCode?.trim()?.length <= 30 &&
      //   website?.trim()?.length > 0 &&
      //   // zipCodeRegex.test(zipCode?.trim()) &&
      //   //multiWordRegex.test(jobTitle?.trim())
      //   jobRoleRegex.test(role?.trim())
      // ) {
      //   // if (multiWordRegex.test(firstName?.trim()) && multiWordRegex.test(lastName?.trim()) && isEmail(email?.trim()) && multiWordRegex.test(jobTitle?.trim()) && multiWordRegex.test(orgName?.trim()) && denomination?.trim()?.length > 0) {
      //   setIsFormValid(true);
      //   console.log("here");
      //   setShowMyResultsBtn(true);
      // } else {
      //   console.log("here2");
      //   console.log(
      //     fullNameRegex.test(fullName?.trim()) &&
      //       isEmail(email?.trim()) &&
      //       isURL(website?.trim())
      //   );
      //   setIsFormValid(false);
      //   setShowMyResultsBtn(false);
      // }

      if (!isMember) {
        if (
          fullNameRegex.test(fullName?.trim()) &&
          isEmail(email?.trim()) &&
          validUrlRegex.test(website?.trim()) &&
          website?.trim()?.length > 0 &&
          jobRoleRegex.test(role?.trim())
        ) {
          setIsFormValid(true);
          setShowMyResultsBtn(true);
        } else {
          setIsFormValid(false);
          setShowMyResultsBtn(false);
        }
      }
    }
  };

  const showErrors = () => {
    // if (!(multiWordRegex.test(firstName?.trim()))) {
    //   setFirstNameError(true);
    //   setTimeout(() => {
    //     console.log(firstNameError);
    //   }, 2000);
    // }
    // if (!multiWordRegex.test(lastName?.trim())) {
    //   setLastNameError(true);
    // }
    if (!fullNameRegex.test(fullName?.trim())) {
      setFullNameError(true);
    }

    if (!isEmail(email)) {
      setEmailError(true);
    }

    const jobRoleRegex = /^[A-Za-z\s\-\/]+$/;
    // if (!jobRoleRegex.test(jobTitle.trim())) {
    //   setJobTitleError(true);
    // }
    if (!jobRoleRegex.test(role.trim())) {
      setRoleError(true);
    }
    // if(schoolName == "") {
    //   setSchoolNameError(true);
    // }
    if (!multiWordRegex.test(orgName.trim())) {
      setOrgNameError(true);
    }
    if (!validUrlRegex.test(website?.trim())) {
      setWebsiteError(true);
    }
    if (!denomination?.trim()?.length > 0) {
      setDenominationError(true);
    }
    if (!role?.trim()?.length > 0) {
      setRoleError(true);
    }
    if (!multiWordRegex.test(givingProvider.trim())) {
      setGivingProviderError(true);
    }

    // if (
    //   !(
    //     zipCode.trim().length >= 5 &&
    //     zipCode.trim().length <= 30 &&
    //     zipCodeRegex.test(zipCode.trim())
    //   )
    // ) {
    //   setZipCodeError(true);
    // }
    if (
      !(
        avgWeeklyAttd.trim().length > 0 &&
        avgWeeklyAttd.trim().length < 5 &&
        !isNaN(avgWeeklyAttd)
      )
    ) {
      setAvgWeeklyAttdError(true);
    }
  };

  const submitToApi = () => {
    let apiCall = "/v1/assessment/" + id + "/leader";
    if (isMember) {
      apiCall = "/v1/assessment/" + id + "/member/" + userId;
    }

    let jsonBody = JSON.stringify({
      details: {
        // firstName: firstName?.trim(),
        // lastName: lastName?.trim(),
        firstName: (fullName?.split(" "))[0],
        lastName: (fullName?.split(" "))[1],
        fullName: fullName?.trim(),
        email: email?.trim(),
        // "orgName": orgName?.trim(),
        website: website?.trim(),
        // zipCode: zipCode?.trim(),
        //jobTitle: jobTitle?.trim(),
        role: role?.trim(),

        // "denomination": denomination.trim(),
        // "avgWeeklyAttendance": avgWeeklyAttd?.trim(),
      },
      formVersion: 2,
    });
    console.log(jsonBody);

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: "PUT",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: jsonBody,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          localStorage.removeItem("assessmentAnswers");
          localStorage.removeItem("additionalAnswers");
          navigate("/results/" + id, { replace: true });
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Running");
    console.log(isFormValid);

    if (isMember && isFormValid) {
      console.log("here1");
      submitToApi();
    } else if (!isMember && isFormValid) {
      console.log("here2");
      submitToApi();
    } else {
      console.log("here3");
      showErrors();
      setIsSaving(false);
    }

    // navigate("/dashboard/" + id , {replace: true});
  };

  const showButton = () => {
    if (isSaving) {
      return (
        <div className="button-progress">
          <CircularProgress style={{ color: "#FFFFFF" }} size={23} />
        </div>
      );
    }
    // return "SHOW MY RESULTS";
    return " ACCESS YOUR REPORT";
  };

  const showHintText = (field) => {
    // if (field == "firstName") {
    //   if (firstNameError) {
    //     return <div className="error-text">Please enter your first name</div>;
    //   }
    // }
    // if (field === "lastName") {
    //   if (lastNameError) {
    //     // return <div className="error-text">Please enter your last name</div>;
    //     return <div className="error-text">Please enter your last name</div>;
    //   }
    // }

    if (field === "fullName") {
      if (fullNameError) {
        return <div className="error-text">Please enter your full name</div>;
      }
    }

    if (field === "givingProvider") {
      if (givingProviderError) {
        return (
          <div className="error-text">Please confirm your giving provider.</div>
        );
      }
    }

    if (field === "email") {
      if (emailError) {
        return (
          // <div className="error-text">Please enter your valid email ID </div>
          <div className="error-text">Please enter your valid email ID</div>
        );
      }
    }

    if (field === "role") {
      if (roleError) {
        return <div className="error-text">Please enter your job role</div>;
      }
    }
    if (field === "jobTitle") {
      if (jobTitleError) {
        // return <div className="error-text">Please enter your role </div>;
        return <div className="error-text">Please enter your job title</div>;
      }
    }
    if (field === "website") {
      if (websiteError) {
        return (
          // <div className="error-text">Please enter your valid website</div>
          <div className="error-text">Please enter your valid website</div>
        );
      }
    }
    if (field === "orgName") {
      if (orgNameError) {
        return (
          <div className="error-text"> Please enter your church’s name</div>
        );
      }
    }
    if (field == "denomination") {
      if (denominationError) {
        return (
          <div className="error-text"> Please select your denomination</div>
        );
      }
    }
    if (field === "zipCode") {
      if (zipCodeError) {
        return (
          // <div className="error-text">Please enter your valid ZIP code</div>
          <div className="error-text">Please enter a valid ZIP code</div>
        );
      }
    }
    // if (field == "avgWeeklyAttd") {
    //   if (avgWeeklyAttdError) {
    //   return <div className="error-text">Please enter your avg. weekly attendance</div>
    //   }
    // }

    // if (field == "churchSize") {
    //   if (churchSize) {
    //     return <div className='error-text'>Please enter church size</div>
    //   }
    // }
  };

  const removeHintText = (field, e) => {
    // change active input field title color
    // if(e.target.closest(".form-detail")) {
    //   document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#4A4A4A")
    //   e.target.closest(".form-detail").querySelector(".input-title").style.color = "#ED2028";
    // }

    if (field === "firstName") {
      setFirstNameError(false);
    }
    if (field === "lastName") {
      setLastNameError(false);
    }
    if (field === "fullName") {
      setFullNameError(false);
    }
    if (field === "email") {
      setEmailError(false);
    }
    if (field === "orgName") {
      setOrgNameError(false);
    }
    if (field === "role") {
      setRoleError(false);
    }
    if (field === "website") {
      setWebsiteError(false);
    }
    // if(field == "denomination") {
    //   setDenominationError(false);
    // }
    if (field === "givingProvider") {
      setGivingProviderError(false);
    }
    if (field === "zipCode") {
      setZipCodeError(false);
    }
    // if (field == "avgWeeklyAttd") {
    //   setAvgWeeklyAttdError(false);
    // }
    if (field === "churchSize") {
      setChurchSizeError(false);
    }
  };

  const prevQuestion = () => {
    // if(searchParams.get("leader")) {
    //   navigate('/assessment/' + id + '/review?' + searchParams, { replace: true });
    // } else {
    //   navigate('/assessment-additional/' + id + "?" + searchParams, { replace: true });
    // }
    localStorage.setItem("backFromDetailsPage", true);
    navigate("/assessment/" + id + "/894?" + searchParams, {
      replace: true,
    });
  };

  console.log("isFormValid", isFormValid);
  console.log("isURL(website)", website?.trim(), isURL(website?.trim()));

  if (isLoading) {
    return (
      <div className="container details loading">
        <CircularProgress style={{ color: "#99BB00" }} size={60} />
      </div>
    );
  }

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      fontFamily: "'Lato', sans-serif",
      color: "#343333,",
      fontSize: "18px",
      fontWeight: 400,
    }),

    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 400 : 400,
    }),

    singleValue: (provided) => ({
      ...provided,
      fontWeight: 400,
      color: "#343333",
      fontSize: "18px",
    }),
  };

  console.log(
    " validUrlRegex.test(website?.trim()) ",
    validUrlRegex.test(website?.trim())
  );

  return (
    <div className="container details">
      <div className="details-container">
        {/* <h1>Thank you, now let's get to your results!</h1> */}
        {/* <h1>
          {/* <img src={hourGlass} width={38} height={38} style={{margin:"-6px 0"}}/> */}
        {/* You're almost done! */}
        {/* </h1> */}
        {/* <p>"<span style={{ color: "#c02b0a" }}>*</span>" indicates required fields</p> */}
        <div style={{ height: "10px" }}></div>
        {/* <p>As we tabulate your report, please confirm your details so that we can email you a copy.</p> */}
        {/* <p className="heading">Thank you for your answers. Now let’s get to your results!</p> */}
        <p className="heading">
          {" "}
          Thank you for your answers. Now let’s get to your report!{" "}
        </p>
        {/* <p className="sub-heading">Please confirm your details so we can email you a copy of your results.</p> */}
        <p className="sub-heading">
          Please confirm your details so we can email you a copy of your
          toolkit.
        </p>

        <div className="form">
          {/* <div className="form-detail">
            <p className="input-title">
              First Name<span className="required-field">*</span>
            </p>
            <TextField
              hiddenLabel
              placeholder="E.g. Simon"
              value={firstName}
              onFocus={(e) => removeHintText("firstName", e)}
              onChange={(e) => {
                updateFirstName(e);
              }}
              fullWidth
              variant="standard"
              size="normal"
              InputProps={{ disableUnderline: true }}
              className="form-details-text"
            />
            {showHintText("firstName")}
          </div> */}

          {/* <div className="form-detail">
            <p className="input-title">
              Last Name<span className="required-field">*</span>
            </p>
            <TextField
              hiddenLabel
              placeholder="E.g. Peter"
              value={lastName}
              onFocus={(e) => removeHintText("lastName", e)}
              onChange={(e) => {
                updateLastName(e);
              }}
              fullWidth
              variant="standard"
              size="normal"
              InputProps={{ disableUnderline: true }}
              className="form-details-text"
            />
            {showHintText("lastName")}
          </div> */}

          <div className="form-detail">
            <p className="input-title">
              Full Name<span className="required-field">*</span>
            </p>
            <TextField hiddenLabel placeholder="E.g. Simon Peter" value={fullName} onFocus={(e) => removeHintText("fullName", e)} onChange={(e) => { updateFullName(e); }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("fullName")}
          </div>

          <div className="form-detail">
            <p className="input-title">
              Email<span className="required-field">*</span>
            </p>
            <TextField hiddenLabel placeholder="E.g. speter@rock.church" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => { updateEmail(e); }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" type="email" />
            {showHintText("email")}
          </div>

          {/*             
          {!isMember && <div className='form-detail'>
            <p className='input-title'>Role<span className='required-field'>*</span></p>
            <Select classNamePrefix="react-select" className="form-details-text react-select"
              styles={{dropdownIndicator: (provided) => ({...provided, color: '#21165A'})}}
              options={roleValues}
              onFocus={(e) => removeHintText("role", e)}
              onChange={updateRole}
              value={selectedRole}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#F26722',
                  primary: '#F26722',
                  neutral0: '#f4f4f4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"E.g. Chief of IT"} />
            {showHintText("role")}
          </div>} */}
          <div className="form-detail">
            <p className="input-title">
              Role<span className="required-field">*</span>
            </p>
            {/* Added react-select instead of MUI TextField */}
            <Select styles={customStyles} classNamePrefix="react-select" className="form-details-text react-select" options={roleValues} onFocus={(e) => removeHintText("role", e)} onChange={updateRole} value={selectedRole} theme={(theme) => ({  ...theme, colors: { ...theme.colors, primary25: "#99BB00", primary50: "#99BB00", primary: "#99BB00", neutral0: "#F3F3F3", neutral50: "#868686", }, })} placeholder={"Select/Start Typing"} />
            {showHintText("jobTitle")}
          </div>

          {/*  <div className="form-detail">
            <p className="input-title">
              Job title<span className="required-field">*</span>
            </p> */}
          {/* <Select classNamePrefix="react-select" className="form-details-text react-select"
              options={jobTitleValues}
              onFocus={(e) => removeHintText("job", e)}
              onChange={(e) => updateJobTitle(e)}
              value={selectedDenomination}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#99BB00',
                  primary50: '#99BB00',
                  primary: '#99BB00',
                  neutral0: '#F3F3F3',
                  neutral50: '#868686', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} />
            {showHintText("Start typing or select")} */}

          {/* <TextField select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option below</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}

          {/* Added react-select instead of MUI TextField */}
          {/* <Select
              classNamePrefix="react-select"
              className="form-details-text react-select"
              options={jobTitleValues}
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle}
              value={selectedJobTitle}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#99BB00",
                  primary50: "#99BB00",
                  primary: "#99BB00",
                  neutral0: "#F3F3F3",
                  neutral50: "#868686", // placeholder text color
                },
              })}
              placeholder={"Start typing or select"}
            />
            {showHintText("jobTitle")}
            </div> */}

          {!isMember && (
            <div className="form-detail">
              <p className="input-title">
                Church/Org Website<span className="required-field">*</span>
              </p>
              {/* <Select classNamePrefix="react-select" className="form-details-text react-select"
              options={churchNameValues}
              onFocus={(e) => removeHintText("orgName", e)}
              onChange={updateOrgName}
              value={selectedOrgName}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#FC2B38',
                  primary: '#FC2B38',
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"E.g. Rock Church"} />  */}
              <TextField hiddenLabel placeholder="E.g. www.rockchurch.com" value={website} onFocus={(e) => removeHintText("website", e)} onChange={(e) => { updateWebsite(e); }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
              {showHintText("website")}
            </div>
          )}

          {/* {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">School name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Rock Church" value={schoolName} onFocus={(e) => removeHintText("schoolName", e)} onChange={(e) => {updateSchoolName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("orgName")}
              </div>
            ) : null
          } */}

          {/* {!isMember && <div className="form-detail school-name">
            <p className="input-title">Website<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. rockchurch.org" value={website} onFocus={(e) => removeHintText("website", e)} onChange={(e) => { updateWebsite(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("website")}
          </div>}

          {!isMember && <div className="form-detail">
            <p className="zipcode">ZIP Code<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. 11618" value={zipCode} onFocus={(e) => removeHintText("zipCode", e)} onChange={(e) => { updateZipCode(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("zipCode")}
          </div>} */}

          {/* {!isMember && <div className="form-detail">
            <p className="zipcode">Church Size<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. 800" value={churchSize} onFocus={(e) => removeHintText("churchSize", e)} onChange={(e) => { updateChurchSize(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("churchSize")}
          </div>} */}

          {/* {!isMember && <div className="form-detail">
            <p className="zipcode">Avg. Weekly Attendance<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g. 800" value={avgWeeklyAttd} onFocus={(e) => removeHintText("avgWeeklyAttd", e)} onChange={(e) => { updateAvgWeeklyAttd(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("avgWeeklyAttd")}
          </div>} */}

          {/* <div className="form-detail">
            <p className="input-title">Current giving provider<span className="required-field">*</span></p>
            <Select classNamePrefix="react-select" className="form-details-text react-select"
              options={givingValues}
              onFocus={(e) => removeHintText("givingProvider", e)}
              onChange={updateGivingProvider}
              value={selectedProvider}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#61CE70',
                  primary: '#F18132',
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} />
            {showHintText("givingProvider")}
          </div> */}

          {/* {!isMember && <div className="form-detail">
            <p className="input-title">
              ZIP/Postal Code<span className="required-field">*</span>
            </p>
            <TextField hiddenLabel placeholder="E.g.: Protestant" value={denomination} onFocus={(e) => removeHintText("denomination", e)} onChange={(e) => {updateDenomination(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            <TextField
              hiddenLabel
              placeholder="E.g. 11618"
              value={zipCode}
              onFocus={(e) => removeHintText("zipCode", e)}
              onChange={(e) => {
                updateZipCode(e);
              }}
              fullWidth
              variant="standard"
              size="normal"
              InputProps={{ disableUnderline: true }}
              className="form-details-text"
            />
            {showHintText("zipCode")}
          </div>

          } */}

          {/* <div className="form-detail">
            <p className="input-title">Denomination<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="  Select/start typing" value={denomination} onFocus={(e) => removeHintText("denomination", e)} onChange={(e) => { updateDenomination(e) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("denomination")}
          </div> */}
        </div>
        <div className="details-buttons">
          {/* <div className="back-btn">
            {!isMember && <Button onClick={prevQuestion}>
              <img src={prevArrow} alt="previous button" />
            </Button>}
          </div> */}
          <Button onClick={prevQuestion} className="back-btn">
            <img src={prevArrow} alt="previous button" />
          </Button>

          <div className={showMyResultsBtn ? "cta-btn-active" : "cta-btn"}>
            <Button
              onClick={handleSubmit}
              style={{ fontSize: "21px" }}
              disabled={btnDisabled}
              // disabled={false}
            >
              {showButton()}
            </Button>
          </div>
          {/* <div className="btn" style={{ width: "50px" }}></div> */}
        </div>

        {/* {isMember && <div className="back-btn bottom">
          <Button onClick={prevQuestion}>
            <img src={prevArrow} alt="previous button" />
          </Button>
        </div>} */}
      </div>
    </div>
  );
};

export default Details;
