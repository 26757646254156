import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AssessmentTypeContext from "../../Store/AssessmentTypeContext";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import prevArrow from '../../Assets/Images/prevArrow.svg';
import { Button } from "@mui/material";
// import CommunicationIcon from '../../Assets/Images/chat.png';
// import CommunityIcon from '../../Assets/Images/group.png';
// import EmployeeBenefitsIcon from '../../Assets/Images/life-insurance.png';
// import FinancialSystemsIcon from '../../Assets/Images/money.png';
// import FundraisingIcon from '../../Assets/Images/donation.png';
// import InformationTechIcon from '../../Assets/Images/global.png';
// import InsuranceIcon from '../../Assets/Images/insurance.png';
// import MobilizationIcon from '../../Assets/Images/mobility.png';
// import PeopleCareIcon from '../../Assets/Images/growth.png';


const Explainer = () => {

  const { id } = useParams()
  const navigate = useNavigate()
  const assessmentTypeCtx = useContext(AssessmentTypeContext);
  const [gridItems, setGridItems] = useState()

  const { windowWidth } = useWindowDimensions()

  // const gridItems = [
  //   { label: "Communication", icon: CommunicationIcon },
  //   { label: "Community", icon: CommunityIcon },
  //   { label: "Employee Benefits", icon: EmployeeBenefitsIcon },
  //   { label: "Financial Systems", icon: FinancialSystemsIcon },
  //   { label: "Fundraising", icon: FundraisingIcon },
  //   { label: "IT & Management", icon: InformationTechIcon },
  //   { label: "Insurance", icon: InsuranceIcon },
  //   { label: "Deployment", icon: MobilizationIcon },
  //   { label: "Peoplecare", icon: PeopleCareIcon },
  // ];




  useEffect(() => {
    const gridItemsMinistry = [
      { label: "Communication" },
      { label: "Community" },
      { label: "Employee Benefits" },
      { label: "Financial Systems" },
      { label: "Fundraising" },
      { label: "IT & Management" },
      { label: "Insurance" },
      { label: "Deployment" },
      { label: "Peoplecare" },
    ];



    const gridItemsChurch = [
      { label: "Communication" },
      { label: "Community" },
      { label: "Employee Benefits" },
      { label: "Financial Systems" },
      { label: "IT & Management" },
      { label: "Insurance" }
    ];

    if (assessmentTypeCtx?.assessmentType === 'Church') {
      setGridItems(gridItemsChurch)
    }
    else if (assessmentTypeCtx?.assessmentType === 'Ministry') {
      setGridItems(gridItemsMinistry)
    }
  }, [assessmentTypeCtx])


  const goToAssessment = () => {
    navigate("/assessment/" + id + "/894");
  }
  const handleback = () => {
    navigate("/start/" + id );
  }

  return (
    <div className="explainer">
      <div className="container">
        <div className="container-flex">
          {
            windowWidth < 550 ?
              <>
                <div className="right-content">
                  <p className="first">Evaluate the effectiveness of your toolkit<br className="right-content-br" /> through <span className="highlight">{
                    assessmentTypeCtx?.assessmentType === 'Church' ? "25 queries" : "34 queries"
                  }</span> across <span className="highlight">{
                    assessmentTypeCtx?.assessmentType === 'Church' ? "6 key metrics" : "9 key metrics"
                  }</span>.
                  </p>
                  <p className="specification">Specify which tools/solutions/platforms you’re currently using for each of these queries.
                  </p>
                </div>
                <div className="left-box">
                  <div className="grid-container">
                    {
                      gridItems?.map((item, index) => (
                        <div key={index} className="grid-item" onMouseEnter={(e) => {
                          e.currentTarget.style.background = "#99BB00"
                        }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.background = "#4a90e2"
                            e.currentTarget.style.opacity = "70%"
                          }}
                        >{item?.label}</div>
                      ))
                    }
                  </div>
                </div>
                <div className="submit-container">
                  <button className={"begin-button"} onClick={goToAssessment}>Continue</button>
                  <p className="note"><span style={{ fontWeight: "700" }}>Note:</span> Your progress and responses are saved automatically.</p>
                </div>
              </>
              :
              <>
                <div className="left-box">
                  <div className="grid-container">
                    {
                      gridItems?.map((item, index) => (
                        <div key={index} className="grid-item" onMouseEnter={(e) => {
                          e.currentTarget.style.background = "#99BB00"
                        }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.background = "rgba(0, 97, 177, 0.7)"
                            // e.currentTarget.style.opacity = "70%"
                          }}
                        >{item?.label}</div>
                      ))
                    }
                  </div>
                </div>
                <div className="right-content">
                  <p className="first">Identify where you are already equipped and where you need to be strengthened</p>
                  {/* <p className="first">Evaluate the effectiveness of your toolkit through <span className="highlight">{
                      assessmentTypeCtx?.assessmentType === 'Church' ? "25 queries" : "34 queries"
                    }</span> across <span className="highlight">{
                      assessmentTypeCtx?.assessmentType === 'Church' ? "6 key metrics" : "9 key metrics"
                    }</span>.
                    </p> */}
                  <p>Specify which tools or solutions you're currently using across these {assessmentTypeCtx?.assessmentType === 'Church' ? "6" : "9"} core aspects of organizational management
                  </p>
                  <button className={"begin-button"} onClick={goToAssessment}>Continue</button>
                  <p className="note"><span>Note:</span> Your progress and responses are saved automatically.</p>
                </div>
              </>
          }
        </div>
        <div className="explainer-button">
          <Button onClick={handleback} className="prev-btn">
            <img className="margin-left" src={prevArrow} alt="previous button" />
          </Button>
        </div>
      </div>

    </div>
  );
};

export default Explainer;