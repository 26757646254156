import React, { createContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const AssessmentTypeContext = createContext()

export function AssessmentTypeContextProvider(props) {


  const location = useLocation();

  const pathnameArray = location.pathname.split('/')
  
  const [type, setType] = useState(localStorage.getItem(`assessmentType_${pathnameArray[2]}`))
  

  const updateAssessmentTypeHandler = ( typePayload, assessmentId ) => {
    localStorage.setItem(`assessmentType_${assessmentId}`,typePayload)
    setType(typePayload)
  }

  const context = {
    assessmentType: type,
    updateAssessmentType: updateAssessmentTypeHandler
  }

  return (
    <AssessmentTypeContext.Provider value={context}>
      {props.children}
    </AssessmentTypeContext.Provider>
  )
}

export default AssessmentTypeContext;