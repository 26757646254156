import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import assess from '../../Assets/Images/assess.svg';
import benchmark from '../../Assets/Images/benchmark-img.png';
import calibrate from '../../Assets/Images/calibrate.svg';
import analyzeIcon from '../../Assets/Images/analyze.svg';
import alignIcon from '../.././Assets/Images/align.svg'
import advanceIcon from '../../Assets/Images/advance.svg';
import Testimonial from '../Testimonial/Testimonial.jsx';
import ShareIcon from "../../Assets/Images/share-icon.png"
import MiscContext from '../../Store/MiscContext.jsx';


const InviteInfo = ({hasInvite}) => {

  const { id } = useParams();
  const currentLocation = useLocation();
  const miscCtx = useContext(MiscContext);
  const invite = useRef(null);

  useEffect(() => {
    if(hasInvite){
      if (currentLocation.hash === "#invite") {
        // invite.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        invite.current.scrollIntoView({ behavior: "smooth" });
      }
    }
   
  }, [currentLocation]);

  return (
    // <div className="invite-container">
    //   <div className="invite-container-spacer">
    //     <h3>Champion greater financial discipline</h3>
    //     <p>Invite more church leaders to take this assessment to help them assess and improve their financial practices. Your invitation can go a long way in aligning the Church’s financial footprints with its mission and vision.</p>

    //     {/* <div className="invite-reasons">
    //       <div className="reason">
    //         <img src={analyzeIcon} alt="insights icon" /><br />
    //         <div className="height30"></div>
    //         <span>Assess</span><br />
    //         Measure your performance in key areas concerning engagement
    //       </div>

    //       <div className="reason">
    //         <img src={alignIcon} alt="alignment icon" /><br />
    //         <div className="height30"></div>
    //         <span>Benchmark</span><br />
    //         Check how you compare with similarly-sized churches
    //       </div>

    //       <div className="reason">
    //         <img src={advanceIcon} alt="direction icon" /><br />
    //         <div className="height30"></div>
    //         <span>Calibrate</span><br />
    //         Align around action based on carefully assessed data
    //       </div>

    //     </div> */}

    //     <div className="cta-btn">
    //       <Link to={"/invite-team/" + id} target="_blank">
    //         <Button>INVITE MY PEERS</Button>
    //       </Link>
    //     </div>

    //     {/* <Testimonial /> */}


    //   </div>

    //   {/* <div className="testimonial-invite">
    //     <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
    //     <div className="cta-btn">
    //       <Link to={"/invite-team/" + id} target="_blank">
    //         <Button>INVITE YOUR TEAM</Button>
    //       </Link>
    //     </div>
    //   </div> */}

    // </div>

    <div className="invite-section-container" ref={invite}>
      <h3>Invite your team to review their tools</h3>
      <p>Invite your team to review as well so that you can visualize your entire team’s toolkit, including individual inputs and your overall team score.</p>
      <div className="invite-container">
        <div className="assess">
          <img src={assess} alt="assess" />
          <h3>Assess</h3>
          <p>Gain a complete picture of your team’s toolkit</p>
        </div>
        <div className="benchmark">
          <img src={benchmark} alt="benchmark" className='benchmark-img' />
          <h3>Identify</h3>
          <p>Understand how your team is equipped and where it’s not</p>
        </div>
        <div className="calibrate">
          <img src={calibrate} alt="calibrate" />
          <h3>Calibrate</h3>
          <p>Identify which other tools your team needs today</p>
        </div>
      </div>
      <div className="cta-btn">
        {/* <Link to={"/invite-team/" + id} target="_blank"> */}
        <Button className="cta" onClick={() => miscCtx.setShowInviteSection(true)}>Invite Your Team</Button>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default InviteInfo;
