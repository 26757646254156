import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import prevArrow from "../../Assets/Images/prev-arrow.svg";
import nextArrow from "../../Assets/Images/next-arrow.svg";

import testimonialImage from "../../Assets/Images/testimonial.png";
import scheduledImage from "../../Assets/Images/schedule-image.png";
import quotationImage from "../../Assets/Images/quotation.svg";
// import TeamDashboard from '../../Assets/Images/sample-team-dashboard-desktop.png';
// import TeamDashboardMobile from '../../Assets/Images/sample-dash-team-mobi.png';
// import IndividualDashboard from '../../Assets/Images/sample-individual-dashboard.png';
// import IndividualDashboardMobile from '../../Assets/Images/sample-dash-indi-mobi.png';

import TeamDashboardChurch from '../../Assets/Images/sd-church-team-desktop.png';
import IndividualDashboardChurch from '../../Assets/Images/sd-church-individual-desktop.png';
import IndividualDashboardChurchMobile from '../../Assets/Images/sd-church-individual-mobile.png';
import TeamDashboardChurchMobile from '../../Assets/Images/sd-church-team-mobile.png';


import TeamDashboardMinistry from '../../Assets/Images/sd-ministry-team-desktop.png';
import IndividualDashboardMinistry from '../../Assets/Images/sd-ministry-individual-desktop.png';
import IndividualDashboardMinistryMobile from '../../Assets/Images/sd-ministry-individual-mobile.png';
import TeamDashboardMinistryMobile from '../../Assets/Images/sd-ministry-team-mobile.png';



import useWindowDimensions from '../../Hooks/useWindowDimensions';
import { sectionsText } from "../../globalFunctions";
import InviteInfo from "../../Components/InviteInfo/InviteInfo.jsx";
import Testimonial from "../../Components/Testimonial/Testimonial.jsx";
import Invite from "../Invite/Invite.jsx";
import InviteThankYou from "../InviteThankYou/InviteThankYou.jsx";

import ClickableCircularProgressBars from "../../Components/ClickableCircularProgressBars";
// import DonutChart from "../Components/DonutChart";
// import DonutChartAccordion from "../Components/DonutChartAccordion";


import { Accordion, AccordionSummary, AccordionDetails, Box, Switch, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, Dashboard, Remove } from "@mui/icons-material";
import CallInfo from "../../Components/CallInfo/CallInfo.jsx";
import { questions_statistics } from "../../temp.js";
import plus from '../../Assets/Images/plus.svg';
import subtract from '../../Assets/Images/subtract.svg';
import arrow from '../../Assets/Images/arrow-right-solid.svg';
import MiscContext from "../../Store/MiscContext.jsx";
import AssessmentTypeContext from "../../Store/AssessmentTypeContext.jsx";


console.log(questions_statistics);
const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

const Results = (props) => {

  let navigate = useNavigate();
  const { id } = useParams();
  const { windowWidth } = useWindowDimensions();
  const miscCtx = useContext(MiscContext);
  const assessTypeCtx = useContext(AssessmentTypeContext)

  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [apiData, setApiData] = useState();
  const [name, setName] = useState("");
  const [score, setScore] = useState(0);
  const [sections, setSections] = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScore6, setSubScore6] = useState(0);
  const [subScore7, setSubScore7] = useState(0);
  const [subScore8, setSubScore8] = useState(0);
  const [subScore9, setSubScore9] = useState(0);

  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0]);
  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [largeScreen, setLargeScreen] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [newSections, setNewSections] = useState([]);
  const [viewmoreToolkit, setViewmoreToolkit] = useState('');
  const [chartsData, setChartsData] = useState();
  const [orgType, setOrgType] = useState("")


  useEffect(() => {
    // document.body.style.backgroundImage = "none";
    // document.body.style.background = "#004F88";
    // document.body.style.backgroundColor = "#004F88";
    getQuestions();
    fetchResults();
    localStorage.removeItem("SELECTED_CATEGORY");
  }, []);

  useEffect(() => {
    if (sections?.length > 0) {
      setViewmoreToolkit(sections[0]?.section);
    }
  }, [sections]);

  useEffect(() => {
    // basic check for API data reliability 
    if (apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        {
          title: "The community around us is aware of our mission.",
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1 }
        },
        {
          title: "How many engagement opportunities did you host in the past year open to the larger community?",
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2 }
        },
        {
          title: "Throughout last year, how many community members participated in these engagement opportunities?",
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3 }
        },
        {
          title: "Over time, our church's engagement with our community has been...",
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4 }
        },
        {
          title: "Our community frequently utilizes our church's building facilities and programs.",
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5 }
        },
      ]);
    }
  }, [apiData]);

  useEffect(() => {
    if (windowWidth > 830) {
      setLargeScreen(true);
    }
    else {
      setLargeScreen(false);
    }
  }, [windowWidth]);

  console.log("chartsData", chartsData);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };

    if (orgType === "Church") {
      setSubScores([subScore1, subScore2, subScore3, subScore4, subScore6, subScore7]);
    }
    else {
      setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9]);
    }

    // console.log("orgType: ",orgType)

  }, [orgType, subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9]);

  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      // fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === "success") {
        console.log("data: ", data);

        setApiData(data);
        const fullName = data.fullName.trim();
        const nameParts = fullName.split(' ');
        const displayName = nameParts[0] ? nameParts[0] : nameParts[nameParts.length - 1];
        setName(data.name === "" ? displayName : data.name);

        const churchScore = data?.variables?.find(item => item.variable === "churchper")
        const ministryScore = data?.variables?.find(item => item.variable === "ministryper")

        const orgType = data?.variables?.find(item => item.variable === 'orgType')

        console.log("orgType: ",orgType)

        setOrgType(orgType?.scoreTxt)

        if (orgType?.scoreTxt === "Church") {
          setScore(churchScore?.score);
        }
        else {
          setScore(ministryScore?.score);
        }

        const subScore1Var = data?.variables?.find(item => item?.variable === "sec1per")
        const subScore2Var = data?.variables?.find(item => item?.variable === "sec2per")
        const subScore3Var = data?.variables?.find(item => item?.variable === "sec3per")
        const subScore4Var = data?.variables?.find(item => item?.variable === "sec4per")
        const subScore5Var = data?.variables?.find(item => item?.variable === "sec5per")
        const subScore6Var = data?.variables?.find(item => item?.variable === "sec6per")
        const subScore7Var = data?.variables?.find(item => item?.variable === "sec7per")
        const subScore8Var = data?.variables?.find(item => item?.variable === "sec8per")
        const subScore9Var = data?.variables?.find(item => item?.variable === "sec9per")

        if (orgType?.scoreTxt === "Church") {
          const filteredSections = data.sections.filter(section => section.id !== 316 && section.id !== 317 && section.id !== 313);
          setSections(filteredSections);
        }
        else {
          setSections(data.sections);
        }

        setSubScore1(subScore1Var?.score);
        setSubScore2(subScore2Var?.score);
        setSubScore3(subScore3Var?.score);
        setSubScore4(subScore4Var?.score);
        setSubScore5(subScore5Var?.score);
        setSubScore6(subScore6Var?.score);
        setSubScore7(subScore7Var?.score);
        setSubScore8(subScore8Var?.score);
        setSubScore9(subScore9Var?.score);


        setIsMember(data.isMember);
        // console.log(data);

        setIsLoading(false);

      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to fetch results...", err);
    });
  };

  console.log("apiData", apiData)

  function getQuestions() {
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/894', {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        console.log(data);

        const orderedQuestions = data.data.questions.sort((a, b) => a.orderNo - b.orderNo);
        setQuestions(orderedQuestions);
        setNewSections(data.data.sections);
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Error while fetching questions...", err));
    // }
  }

  // const showTitleText = () => {
  //   if (score > 0 && score <= 50) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Sub-optimal</h1>
  //   } else if (score > 50 && score <= 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Adequate</h1>
  //   } else if (score > 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Perfect</h1>
  //   }
  // };

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: Math.round(subScores[idx]), orderNo: sec.orderNo }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.orderNo - b.orderNo);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);
  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }
  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const showTitleText = () => {
    if (score >= 0 && score <= 50) {
      return <h1>{name}, your ministry seems to be lacking tools across multiple aspects.</h1>
    } else if (score > 50 && score <= 80) {
      return <h1>{name}, your ministry seems to be moderately equipped but could do with more.</h1>
    } else if (score > 80) {
      return <h1>{name}, your ministry seems to be well equipped across most aspects.</h1>
    } else {
      return <h1>{name}, your ministry seems to be lacking tools across multiple aspects.</h1>
    }
  };

  const showScoreText = () => {
    const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);

    if (allScoresSame) {
      return <div className="result-text">
        <p style={{ margin: "10px auto 30px" }}>We're glad to see you're maintaining a balanced focus across all fronts of your community engagement strategy.</p>
        <p style={{ margin: "10px auto 5px" }}>Here are some quick tips to elevate engagement through all areas and maximize your Kingdom impact:</p>
        <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
      </div>
    } else return <div className="result-text">
      <p style={{ margin: "10px auto 30px" }}>Keep up the good work on the <span style={{ fontWeight: "700" }}>{highestScoreObject?.section?.toLowerCase()}</span> front and pay special attention to <span style={{ fontWeight: "700" }}>{sortedSectionsAndSubScores[0]?.section?.toLowerCase()}</span>.</p>
      <p style={{ margin: "10px auto 5px" }}>Here are some quick tips:</p>
      <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    </div>
    // if (score > 0 && score <= 50) {
    //   return <div className="result-text">
    //     <p></p>
    //   </div>
    // } else if (score > 50 && score <= 80) {
    //   return <div className="result-text">
    //     <p></p>
    //   </div>
    // } else if (score > 80) {
    //   return <div className="result-text">
    //     <p></p>
    //   </div>
    // }
  };

  console.log(orgType);

  const getSampleDashTeamImages = () => {
    if (largeScreen) {
      if (orgType?.toLocaleLowerCase() === 'church') {
        return TeamDashboardChurch
      } else {
        return TeamDashboardMinistry
      }
    } else {
      if (orgType?.toLocaleLowerCase() === 'church') {
        return TeamDashboardChurchMobile
      } else {
        return TeamDashboardMinistryMobile
      }
    }

  }

  const getSampleDashIndiviImages = () => {
    if (largeScreen) {
      if (orgType?.toLocaleLowerCase() === 'church') {
        return IndividualDashboardChurch
      } else {
        return IndividualDashboardMinistry
      }
    } else {
      if (orgType?.toLocaleLowerCase() === 'church') {
        return IndividualDashboardChurchMobile
      } else {
        return IndividualDashboardMinistryMobile
      }
    }

  }

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showDashboard = () => {
    return (
      <>
        <div className="dashboard-heading">
          <p>Scroll down to see what a sample team report looks like
            <br />
            <img src={arrow} alt="down arrow" />
          </p>
          <div className="sample-heading">
            <div className="left"></div>
            <div className="center">SAMPLE DASHBOARD</div>
            <div className="right"></div>
          </div>
        </div>
        {/* <h1>SAMPLE DASHBOARD</h1> */}
        <div div className="individual-group sample" style={{ display: "flex" }} >
          <button onClick={() => setCurrentView(0)} className={currentView == 0 ? "active left" : "left"} >Team</button>
          <button onClick={() => setCurrentView(1)} className={currentView == 1 ? "active right" : "right"}>Individual</button>
        </div >
        <div className="results-dashboard-container">
          <img src={currentView === 0 ? getSampleDashTeamImages() : getSampleDashIndiviImages()} alt="" />
        </div>
      </>
    )
  }

  // const showInvite = () => {
  //   // if (isMember) return null;
  //   // else if (props?.showInvite === false) return null;
  //   // else return (<InviteInfo />)
  //   return (<InviteInfo />)
  // };

  const showInvite = (props) => {
    // if (isMember) return null;
    //   else if (props?.showInvite === false) return null;
    //   else return (<InviteInfo />);
    if (!miscCtx?.showInviteSection && !miscCtx?.isInviteSuccessful) {
      if (isMember) return null;
      else if (props?.showInvite === false) return null;
      else return (<InviteInfo hasInvite={props?.hasInvite} />);
    }

    if (miscCtx?.showInviteSection && !miscCtx?.isInviteSuccessful) {
      return (<Invite />);
    }

    if (miscCtx.isInviteSuccessful) {
      return (<InviteThankYou />);
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return <Testimonial />;
    } else {
      // return null;
      return <Testimonial />;
    }
  };

  const showCallInfo = () => {
    if (!isMember) {
      return <CallInfo />;
    }
  }

  const getLeftMargin = (score) => {
    if (windowWidth <= 830) {
      if (score > 96 && score < 100) {
        return { left: 96.5 + "%" };
      } else if (score >= 100) {
        return { left: 97.5 + "%" };
      } else if (score > 0 && score < 3) {
        return { left: 2.5 + "%" };
      } else if (score <= 0) {
        return { left: 1.5 + "%" };
      } else {
        return { left: score + "%" };
      }
    } else if (score <= 0) {
      return { left: 0 + "%" }
    } else {
      return { left: Math.round(score) + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            <img src={scheduledImage} />
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
          <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>Talk to Kim</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Here are your next steps:</h1>
            <div className="steps">
              <ol>
                <li>Invite your leadership team to take this test.</li>
                <li>Unlock your team's individual responses and team scores.*</li>
              </ol>
            </div>
            {/* <p>Get your team to take this test and see if they think your church is guest ready.</p> */}
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>Invite My Team</Button>
              </Link>
            </div>
            <p>*Scroll down to see what your dashboard may look like.</p>
          </div>
        </div>
      );
    }
  };

  console.log("sections", newSections)

  console.log("questions", questions)

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: '#004F88' }} size={60} />
      </div>
    );
  }
  const viewmoreToolkitfun = (value) => {
    console.log("toolkitVAlue", viewmoreToolkit)
    if (viewmoreToolkit === value)
      setViewmoreToolkit(null);
    else
      setViewmoreToolkit(value);
  }

  console.log("questions", questions)
  return (
    <>
      <div className="container results">
        <div className="details-container">
          {showTitleText()}
          <div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="transparent" style={{ width: `${100 - (Math.round(score))}%` }}></div>
                {/* <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
                    <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div>
                </div> */}
                <span className="msi_score" style={getLeftMargin(Math.round(score))}>{Math.round(score) <= 0 ? 0 : Math.round(score)}%</span>
                <div className="score-arrow" style={{ left: Math.round(score) <= 0 ? 0 : Math.round(score) + '%' }}></div>
              </div>
              <div className="pervalue">
                <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>

              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>

              {/* <div className="pervalue-text"> */}
              {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
              {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
              {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
              {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}




              <div className="pervalue-text">
                <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}>Under <br /> Equipped</p>
                {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>Fairly<br /> Equipped</p>
                {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Well <br /> Equipped</p>
              </div>

            </div>
          </div>
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {/* {showScoreText()} */}
          <h3 className="pre-score-header">{name}, {isMember ? "your team seems to be" : " you seem to be"} <b>{Math.round(score) <= 0 ? 0 : Math.round(score)}%</b> equipped.<br />Below are your scores and details per category.</h3>

          {/* <ClickableCircularProgressBars sectionsAndSubScores={sectionsAndSubScores} sortedSectionsAndSubScores={sortedSectionsAndSubScores} /> */}

          {/* <div className="score-section-container">
            {sectionsAndSubScores?.map(sec => {
              return (
                <div key={sec.section} className="score-section">
                  <p className="score-name">{sec.section}</p>
                  <p className="score-txt" style={{
                    // marginLeft: `calc(${parseInt(sec.score)}% - 3ch)` 
                  }}>{Math.round(sec.score)}%</p>
                  <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)} />
                  </div>
                </div>)
            })}
          </div> */}

          <div className="score-section-container">
            {
              sortedSectionsAndSubScores?.map((data, index) => {
                return (
                  <div className="rowdata" key={index}>
                    <p className="colnname">{data?.section}</p>
                    <div className="colscore">
                      <div
                        style={{
                          width: `${data.score + 5}%`,
                          background: data.score < 50 ? '#DD5A3B' : '#0061B1',
                        }}
                      ></div>
                      <h3 style={{ color: data.score < 50 ? '#DD5A3B' : '#0061B1' }}>
                        {data.score}%
                      </h3>
                    </div>
                  </div>
                );
              })
            }

          </div>

          {/* <div className="charts-accordions-container">
            {newSections?.map((section, idx) => {
              return (<Accordion defaultExpanded={idx === 0 ? true : false}>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <p>{section.section}</p>
                </AccordionSummary>
                <AccordionDetails>
                  // <DonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={false} />
                  {
                    questions?.map((question, index) => {
                      if (question.sectionId === section.id) {
                        const answer = apiData?.answers?.find(ans => ans.question_id === question.id)?.answer || "-";
                        let answerText = "-";
                        switch (answer) {
                          case 1: answerText = "Yes"; break;
                          case 2: answerText = "No"; break;
                          case 3: answerText = "Unsure"; break;
                          default: answerText = "-"; break;
                        }

                        return (
                          <div className="question-info">
                            <div className="question-title">{index + 1}. {question.question}</div>
                            <div className="answer-details">
                              <div className="your-answer">Your Answer: <span>{answerText}</span></div>
                              {(questions_statistics[index]).title && <div className="answer-brief-detail">{(questions_statistics[index]).title}</div>}
                            </div>
                            {questions[index + 1] && questions[index + 1].sectionId === section.id && <div className="border"></div>}
                          </div>
                        );
                      }
                    })
                  }
                </AccordionDetails>
              </Accordion>);
              })
            }
          </div> */}

        </div>
      </div>

      <div className="accordions-section-container">
        <h1>Your Overall Ministry Toolkit</h1>
        <div className="toolkit-container">
          {
            sections?.map((data, i) => {
              return (
                <div className="toolkit">
                  <div className="toolkit-head" key={i} style={viewmoreToolkit === data.section ? { borderRadius: "15px 15px 0 0" } : { borderRadius: "15px" }} onClick={() => { viewmoreToolkitfun(data.section) }}>
                    <p>{data.section}</p>
                    <Button ><img src={viewmoreToolkit === data.section ? subtract : plus} alt="full view" /></Button>
                  </div>
                  {
                    viewmoreToolkit === data.section &&
                    <div className="tool">
                      {
                        questions
                          .filter(que => que.sectionId === data.id)
                          .map((que, index) => {
                            const hasAnswers = que.answers.some(q => q.id === que.optionId);

                            return (
                              <div className="toolkit-body" key={index} style={{ borderLeft: index === 0 || windowWidth < 550 ? "none" : "1px solid #004F88", borderBottom: windowWidth > 550 || index === que.question.length - 1 ?  "none" : "1px solid #004F88", margin: windowWidth < 550 ?  "0 auto" : "none" }}>
                                <h3 style={{ color: "#004F88", fontWeight: 700 }}>{que?.question}</h3>
                                {
                                  hasAnswers ? (
                                    que.answers
                                      .filter(q => q.id === que.optionId)
                                      .map((q, answerIndex) => (
                                        <p key={answerIndex}>{q.desktopText}</p>
                                      ))
                                  ) : (
                                    <p style={{
                                      border: que.answer === 1 ? "2px dotted #E6E6E6" : "none",
                                      borderRadius: que.answer === 1 ? "10px" : "none",
                                      backgroundColor: que.answer === 1 ? "#FBF8F8" : "transparent",
                                      color: que.answer === 0 ? "rgba(85, 85, 85, 0.4)" : "#555555",
                                      padding: "5px",
                                      margin: '5px 0px',
                                    }}>
                                      {
                                        que.answer === 1 ? "None" :
                                          que.answer === 0 ? "- Skipped -" : que.answerTxt
                                      }
                                    </p>
                                  )
                                }
                              </div>
                            );
                          })
                      }
                    </div>
                  }

                </div>
              );
            })
          }
        </div>
      </div>

      {/* {showTeamMember()} */}

      {/* {showLegacyInvite()} */}
      {/* to only use # for first one */}
      {showInvite({ hasInvite: true })}

      {!isMember && showDashboard()}



      {showTestimonial()}

      {showInvite()}


      {/* {showCallInfo()} */}

      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {/* {showLegacyInvite()} */}

      {/* {showInvite()} */}

      {/* </div> */}

    </>

  );
};

export default Results;

